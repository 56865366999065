const Tooltip = {
    sizes: {
      xs: {
        fontSize: "xs",
        px: ".5rem",
        py: ".25rem",
        fontWeight: "400",
      }
    },
  };

  export default Tooltip;